export default [
    {
        title: 'CareDocumentStatus',
        load: () => import('@/modules/Care/Resultados/components/DocumentStatus.vue'),
    },
    {
        title: 'CareConsultaStatus',
        load: () => import('@/modules/Care/Consulta/components/DocumentStatus.vue'),
    },
    {
        title: 'CitaStatus',
        load: () => import('@/modules/Care/Citas/components/CitaStatus.vue'),
    },
    {
        title: 'TypeColumn',
        load: () => import('@/modules/Care/Citas/components/TypeColumn.vue'),
    },
];
